import { Pagination } from '@/helpers/CompositePagination'
import { RESET_STATE } from '@/helpers/ResetState'
import { isArray, set } from 'lodash'
import Vue from 'vue'
import createState from './state'

const createChannelTemplate = () => ({
  channel: null,
  contents: {
    subject: '',
    body: '',
  },
  layout: null,
  force: null,
  isActive: true,
})

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setTriggerSendings(state, payload) {
    state.triggerSendings = payload
  },

  setTriggerSending(state, payload) {
    state.triggerSending = payload

    if (
      isArray(state.triggerSending.customFields) &&
      state.triggerSending.customFields.length === 0
    ) {
      state.triggerSending.customFields = {}
    }
  },

  setTriggerSendingValue(state, { path, value }) {
    set(state.triggerSending, path, value)
  },

  setTriggerSendingCustomFieldValue(state, { key, value }) {
    Vue.set(state.triggerSending.customFields, key, value)
    state.triggerDetail.customFields.find((field) => field.code === key).value =
      value
  },

  setTriggerDetail(state, payload) {
    state.triggerDetail = payload
    state.triggerDetail.customFields.forEach((field, index) => {
      Vue.set(
        state.triggerDetail.customFields[index],
        'value',
        state.triggerSending.customFields[field.code] ?? field.defaultValue,
      )
    })
  },

  setOptionsList(state, { path, value }) {
    set(state.options, path, value)
  },

  setPagination(state, headers) {
    const compositePagination = new Pagination()

    compositePagination.updateFromHeaders(headers)

    const { pagination } = state

    if (pagination !== null) {
      compositePagination.setRowsPerPage(pagination.rowsPerPage)
    }

    state.pagination = compositePagination
  },

  setPaginationRowsPerPage(state, { value }) {
    state.pagination.setRowsPerPage(value)
  },

  setFilters(state, filters) {
    state.filters = filters
  },

  setChannelTemplateValue(state, { index, path, value }) {
    set(state.triggerSending.templates[index], path, value)
  },

  addChannelTemplate(state) {
    state.triggerSending.templates.push(createChannelTemplate())
  },

  removeChannelTemplate(state, index) {
    state.triggerSending.templates.splice(index, 1)
  },
}
